<template>
  <div class="cart__item">
    <div class="cart__item-image">
      <sib-link
        v-if="item"
        @click="$emit('link-clicked')"
        :to="
          buildProductHandle(
            `/products/${item.variant?.product?.handle || 'all'}?variant=${
              item.variant?.id
            }`
          )
        "
        :title="item.title"
      >
        <sib-image
          :alt="item.title"
          :width="1024"
          :height="1024"
          :src="isUseProductImageToPreview ? variantImage : getPreviewImage"
          @error="onPreviewImageError"
          loading="lazy"
        ></sib-image>
      </sib-link>
      <sib-skeleton-loading v-else size="square"></sib-skeleton-loading>
    </div>
    <div
      v-if="item"
      class="cart__item-image-preview link"
      @click="previewImage"
    >
      <ZoominIcon />
      Preview
    </div>
    <div class="cart__item-content">
      <div class="cart__item-title">
        <sib-link
          v-if="item"
          :to="
            buildProductHandle(
              `/products/${item.variant?.product?.handle || 'all'}?variant=${
                item.variant?.id
              }`
            )
          "
          :title="item.title"
          @click="$emit('link-clicked')"
        >
          {{ item.title }}
        </sib-link>
        <template v-else>
          <sib-skeleton-loading :count="2"></sib-skeleton-loading>
        </template>
      </div>
      <div class="cart__item-price">
        <template v-if="item">
          <span class="cart__item-price-final">
            {{ formatPrice(item.price) }}
          </span>
          <span class="cart__item-price-compare" v-if="item.compareAtPrice">
            {{ formatPrice(item.compareAtPrice) }}
          </span>
        </template>
        <template v-else>
          <sib-skeleton-loading width="5em"></sib-skeleton-loading>
        </template>
      </div>
      <div class="cart__item-discount-message" v-if="item?.discountMessage">
        {{ item.discountMessage }}
      </div>
      <div class="cart__item-options">
        <template v-if="item">
          {{
            item.variant.selectedOptions
              ?.map((option) => option.value)
              .join(' / ')
          }}
        </template>
        <template v-else>
          <sib-skeleton-loading width="8em"></sib-skeleton-loading>
        </template>
      </div>
      <div
        v-if="item"
        class="cart__item-image-preview link"
        @click="previewImage"
      >
        <ZoominIcon />
        Preview
      </div>
      <div
        class="cart__item-same-design"
        v-if="item && getUpsellSameDesignProductTitleWith"
      >
        The design of this product will be the same as the product titled '{{
          getUpsellSameDesignProductTitleWith
        }}'
      </div>
      <div
        v-if="item && !isMademineProduct"
        class="cart__item-attributes"
        v-show="filteredAttributes.length"
      >
        <details>
          <summary @click="onTogglePersonalization">Personalized</summary>
          <div class="cart__item-attribute-list">
            <template
              v-for="(attribute, index) of filteredAttributes"
              :key="index"
            >
              <span class="cart__item-attribute-title">
                {{ attribute.key }}
              </span>
              <span class="cart__item-attribute-value">
                {{ attribute.value }}
                <span
                  v-if="hasCustomImageLink(attribute.key)"
                  @click="previewCustomImage(attribute.key)"
                  :class="{
                    'font-weight-normal link text-primary': hasCustomImageLink(
                      attribute.key
                    ),
                  }"
                >
                  Link
                </span>
                <template v-if="hasCustomColor(attribute.key)">
                  <div
                    v-for="(color, i) in getCustomColor(attribute.key).split(
                      ','
                    )"
                    :key="i"
                    class="custom-color-picker"
                    :style="`background:${color}`"
                  ></div>
                </template>
              </span>
            </template>
          </div>
        </details>
      </div>
      <div
        class="cart__item-attributes"
        v-else-if="
          item && isMademineProduct && customTextsMademineProduct.length
        "
      >
        <details>
          <summary @click="onTogglePersonalization">Personalized</summary>
          <div class="cart__item-attribute-list">
            <template
              v-for="(attribute, index) of filteredAttributes"
              :key="index"
            >
              <template v-if="attribute.key == MADEMINE_PREVIEW_ATTRIBUTE_KEY">
                <template
                  v-for="(text, i) in customTextsMademineProduct"
                  :key="i"
                >
                  <span class="cart__item-attribute-title">
                    Text
                    {{
                      `${customTextsMademineProduct.length > 1 ? i + 1 : ''}`
                    }}
                  </span>
                  <span class="cart__item-attribute-value">
                    {{ text }}
                  </span>
                </template>
              </template>
            </template>
          </div>
        </details>
      </div>

      <sib-link
        @click="onClickEditPersonalization"
        :to="customilyEditPersonalizationLink"
        v-if="item && customilyEditPersonalizationLink"
        class="cart__item-edit-persionalization"
        :title="item.title"
      >
        Edit personalization
      </sib-link>
      <div class="cart__item-quantity-container">
        <template v-if="item">
          <div class="cart__item-quantity">
            <Quantity
              @update="$emit('update-quantity', $event, item?.id)"
              base-class="cart__item"
              :quantity="item.quantity"
            />
          </div>
          <div class="cart__item-delete-button">
            <TrashIcon @click="$emit('delete-item', item?.id)" />
          </div>
        </template>
        <template v-else>
          <sib-skeleton-loading width="6em" height="2em"></sib-skeleton-loading>
          <sib-skeleton-loading width="3em" height="2em"></sib-skeleton-loading>
        </template>
      </div>
      <div v-if="isExpressShippingSelected" class="cart__item-express-shipping">
        <input type="checkbox" disabled checked />
        <p class="ml-xs my-0">Express Shipping</p>
      </div>
      <div class="cart__item-bundle-wrapper" v-if="bundleAndSaveItem?.html">
        <div v-html="bundleAndSaveItem?.html"></div>
      </div>
      <div class="cart__item-donate-price mt-xs" v-if="donatePrice && item">
        <span>{{ formatPrice(donatePrice * item.quantity) }}</span> will be
        donated to
        <strong>{{ kolCampaignName }}</strong>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CheckoutLineItem } from '@/types'
import {
  EVENT_TOGGLE_CART_ITEM_PERSONALIZATION,
  EVENT_TRACKING_CLICK_PERSONALIZED_OPTIONS_ON_CART,
  HIDDEN_ATTRIBUTE_PREFIX,
  LINE_ITEM_CUSTOMIZATION_URL_ATTRIBUTE,
  LINE_ITEM_PREVIEW_IMAGE_ATTRIBUTE,
  PREVIEW_IMAGE_ON_CART_LOAD_FAILED,
  PREVIEW_IMAGE_ON_CART_RE_FETCHED,
  MIXPANEL_DESTINATION,
  destinationIntegrations,
  CUSTOM_IMAGE_LINK_PREFIX,
  CUSTOM_AUDIO_LINK_PREFIX,
  CUSTOM_COLOR_PICKER_PREFIX,
  DONATE_PRICE_ATTRIBUTE_KEY,
  KOL_CAMPAIGN_NAME_ATTRIBUTE_KEY,
  PHOTO_ALBUM_ATTRIBUTE_KEY,
  PRODUCT_TAG_CUSTOMILY_OPTIONSET_ONLY,
  MADEMINE_PRODUCT_HANDLE_PREFIX,
  MADEMINE_PREVIEW_ATTRIBUTE_KEY,
  EXPRESS_SHIPPING_ATTRIBUTE_KEY,
  UPSELL_SAME_DESIGN_WITH_PRODUCT_TITLE,
} from '@/utils'
import { computed } from '@vue/reactivity'
import { formatPrice, isProductHasTags } from '@/utils/product'
import ZoominIcon from '@/assets/images/icons/zoom-in.svg'
import TrashIcon from '@/assets/images/icons/trash-icon.svg'
import Quantity from '@/components/product/Quantity.vue'
import type { Fancybox as FancyboxType } from '@fancyapps/ui'
import EventBus from '@/services/eventbus'
import { ref } from 'vue'
import Analytics from '@/services/analytics'
import useSettingStore from '@/store/setting'
import useCheckoutStore from '@/store/checkout'
import useRouteStore from '@/store/route'

interface CartItemProps {
  item: CheckoutLineItem | null
}
const props = withDefaults(defineProps<CartItemProps>(), {
  item: null,
})
const emit = defineEmits<{
  (e: 'link-clicked'): void
}>()

const settingStore = useSettingStore()
const checkoutStore = useCheckoutStore()
const routeStore = useRouteStore()

let Fancybox: FancyboxType | null = null
const isUseProductImageToPreview = ref(false)
const randomPreviewImageVersion = ref('')
let showPersonalization = false

const isCustomilyOptionsetOnly = computed(() =>
  isProductHasTags(props.item?.variant.product, [
    PRODUCT_TAG_CUSTOMILY_OPTIONSET_ONLY,
  ])
)

const customilyEditPersonalizationLink = computed(() => {
  if (!props.item) return ''
  if (props.item.customAttributes.length) {
    const customizationAttribute = props.item.customAttributes.find(
      (elm) => elm.key == LINE_ITEM_CUSTOMIZATION_URL_ATTRIBUTE
    )
    if (customizationAttribute) {
      try {
        const url = new URL(customizationAttribute.value)
        return url.pathname + url.search + url.hash
      } catch (error) {
        return customizationAttribute.value
      }
    }
  }
})

const variantImage = computed(() => {
  if (!props.item) return ''
  return props.item.variant.image?.src
})

const getPreviewImage = computed(() => {
  if (!props.item) return ''
  if (isMademineProduct.value && preivewImageMademineProduct.value)
    return preivewImageMademineProduct.value
  if (isCustomilyOptionsetOnly.value) return variantImage.value
  if (props.item.customAttributes.length) {
    const previewAttribute = props.item.customAttributes.find(
      (elm) => elm.key == LINE_ITEM_PREVIEW_IMAGE_ATTRIBUTE
    )
    if (previewAttribute)
      return previewAttribute.value + randomPreviewImageVersion.value
  }
  return variantImage.value
})

const filteredAttributes = computed(() => {
  return (props.item?.customAttributes || []).filter(
    (elm) => !elm.key.startsWith(HIDDEN_ATTRIBUTE_PREFIX)
  )
})

const isExpressShippingSelected = computed(() => {
  if (!props.item) return
  if (!props.item.customAttributes.length) return

  const expressShippingAttribute = props.item.customAttributes.find(
    (elm) => elm.key == EXPRESS_SHIPPING_ATTRIBUTE_KEY
  )
  if (expressShippingAttribute) return expressShippingAttribute.value == 'true'
})

const donatePrice = computed(() => {
  if (!props.item) return
  if (!props.item.customAttributes.length) return

  const donatePriceAttribute = props.item.customAttributes.find(
    (elm) => elm.key == DONATE_PRICE_ATTRIBUTE_KEY
  )
  if (donatePriceAttribute) return +donatePriceAttribute.value / 100
})
const kolCampaignName = computed(() => {
  if (!props.item) return
  if (!props.item.customAttributes.length) return

  const kolCampaignNameAttribute = props.item.customAttributes.find(
    (elm) => elm.key == KOL_CAMPAIGN_NAME_ATTRIBUTE_KEY
  )
  if (kolCampaignNameAttribute) return kolCampaignNameAttribute.value
})

const bundleAndSaveItem = computed(() => {
  if (
    !settingStore.shop?.bundleAndSave?.length ||
    !checkoutStore.localCartLineItems.length
  )
    return

  // check item is includes bundle products setting
  const bundleItem = settingStore.shop?.bundleAndSave?.find(
    (item) => item.productHandle == props.item?.variant.product?.handle
  )
  if (!bundleItem) return

  // get quantity bundle product in checkout cart
  let checkoutBundleProductsQuantity = 0
  const checkoutBundleProducts = checkoutStore.localCartLineItems.filter(
    (item) => item.variant?.product?.handle == bundleItem.productHandle
  )
  if (checkoutBundleProducts.length) {
    checkoutBundleProducts.forEach((item) => {
      checkoutBundleProductsQuantity += item.quantity
    })
  }

  // get quantity suggestion product in checkout cart
  let checkoutSuggestionProductsQuantity = 0
  const checkoutSuggestionProducts = checkoutStore.localCartLineItems.filter(
    (item) =>
      item.variant?.product?.handle == bundleItem.productHandleSuggestion
  )
  if (checkoutSuggestionProducts.length) {
    checkoutSuggestionProducts.forEach((item) => {
      checkoutSuggestionProductsQuantity += item.quantity
    })
  }

  if (checkoutBundleProductsQuantity > checkoutSuggestionProductsQuantity)
    return bundleItem
})

const isMademineProduct = computed(() =>
  props.item?.variant?.product?.handle?.includes(MADEMINE_PRODUCT_HANDLE_PREFIX)
)

const customTextsMademineProduct = computed(() => {
  if (!isMademineProduct.value) return []
  const previewAttribute = props.item?.customAttributes.find(
    (item) => item.key == MADEMINE_PREVIEW_ATTRIBUTE_KEY
  )
  if (!previewAttribute?.value) return []

  const previewAttributeValue = JSON.parse(previewAttribute.value)

  if (previewAttributeValue?.texts) return previewAttributeValue?.texts || []

  return []
})

const preivewImageMademineProduct = computed(() => {
  if (!isMademineProduct.value) return
  const previewAttribute = props.item?.customAttributes.find(
    (item) => item.key == MADEMINE_PREVIEW_ATTRIBUTE_KEY
  )
  if (!previewAttribute?.value) return

  const previewAttributeValue = JSON.parse(previewAttribute.value)

  if (previewAttributeValue?.images?.length)
    return previewAttributeValue?.images[0]
})

const getUpsellSameDesignProductTitleWith = computed(() => {
  if (!props.item?.customAttributes?.length) return ''
  const upsellSameDesignProductTitleAttribute =
    props.item.customAttributes.find(
      (elm) => elm.key == UPSELL_SAME_DESIGN_WITH_PRODUCT_TITLE
    )
  if (upsellSameDesignProductTitleAttribute)
    return upsellSameDesignProductTitleAttribute.value
})

async function previewImage() {
  if (!Fancybox) {
    Fancybox = (await import('@fancyapps/ui')).Fancybox
  }
  // add a random version to preview image to force cache
  if (isUseProductImageToPreview.value) {
    randomPreviewImageVersion.value = `?&v=${Math.floor(Math.random() * 1000)}`
  }
  if (getPreviewImage.value) {
    const photoAlbum = getCustomPhotoAlbum()
    if (photoAlbum.length) {
      Fancybox.show(
        photoAlbum.map((image) => ({
          src: image,
          type: 'image',
        }))
      )
    } else {
      Fancybox.show([
        {
          src: getPreviewImage.value,
          type: 'image',
        },
      ])
    }
  }

  // Analytics.track(
  //   EVENT_TRACKING_CLICK_PREVIEW_ON_CART,
  //   {},
  //   destinationIntegrations([MIXPANEL_DESTINATION])
  // )
}

async function previewCustomImage(customAttributeKey: string) {
  if (!props.item?.customAttributes?.length || !customAttributeKey) return
  const customLink = getCustomLink(customAttributeKey)
  const customKey = getKeyCustomAttribute(customLink)
  if (!Fancybox) {
    Fancybox = (await import('@fancyapps/ui')).Fancybox
  }
  if (customLink) {
    Fancybox.show([
      {
        src: customLink,
        type: customKey.includes(CUSTOM_IMAGE_LINK_PREFIX) ? 'image' : 'iframe',
      },
    ])
  }
}

function getCustomLink(customAttributeKey: string) {
  if (!props.item?.customAttributes?.length || !customAttributeKey) return ''
  let index = props.item?.customAttributes.findIndex((item) =>
    item.key.includes(`${CUSTOM_IMAGE_LINK_PREFIX} ${customAttributeKey}`)
  )
  if (index === -1) {
    index = props.item?.customAttributes.findIndex((item) =>
      item.key.includes(`${CUSTOM_AUDIO_LINK_PREFIX} ${customAttributeKey}`)
    )
  }
  if (index === -1) return ''
  return props.item?.customAttributes[index].value
}

function getCustomColor(customAttributeKey: string) {
  if (!props.item?.customAttributes?.length || !customAttributeKey) return ''
  let index = props.item?.customAttributes.findIndex((item) =>
    item.key.includes(`${CUSTOM_COLOR_PICKER_PREFIX} ${customAttributeKey}`)
  )
  if (index === -1) return ''
  return props.item?.customAttributes[index].value
}

function getCustomPhotoAlbum() {
  if (!props.item?.customAttributes?.length) return []
  let index = props.item?.customAttributes.findIndex((item) =>
    item.key.includes(PHOTO_ALBUM_ATTRIBUTE_KEY)
  )
  if (index === -1) return []

  return props.item?.customAttributes[index]?.value?.split(',') || []
}

function getKeyCustomAttribute(value: string) {
  if (!props.item?.customAttributes?.length || !value) return ''
  let index = props.item?.customAttributes.findIndex(
    (item) => item.value === value
  )
  if (index === -1) return ''
  return props.item?.customAttributes[index].key
}

function hasCustomImageLink(customAttributeKey: string) {
  return !!getCustomLink(customAttributeKey)
}
function hasCustomColor(customAttributeKey: string) {
  return !!getCustomColor(customAttributeKey)
}

function onTogglePersonalization() {
  showPersonalization = !showPersonalization

  EventBus.trigger(EVENT_TOGGLE_CART_ITEM_PERSONALIZATION)
  // trackingViewPersonalizedOptionsOnCart()
}

// some time, Customily can not generate the preview right away
// so we need to retry to fetch the url and purge the cache
function onPreviewImageError() {
  isUseProductImageToPreview.value = true
  const currentURL = getPreviewImage.value
  Analytics.track(
    PREVIEW_IMAGE_ON_CART_LOAD_FAILED,
    {
      url: currentURL,
      handle: props.item?.variant.product?.handle,
      tags: props.item?.variant.product?.tags,
    },
    destinationIntegrations([MIXPANEL_DESTINATION])
  )
  // wait some moment to let Customily breathe
  setTimeout(async () => {
    if (currentURL) {
      try {
        const response = await fetch(currentURL, { cache: 'reload' })
        Analytics.track(
          PREVIEW_IMAGE_ON_CART_RE_FETCHED,
          {
            url: currentURL,
            handle: props.item?.variant.product?.handle,
            tags: props.item?.variant.product?.tags,
            stauts: response.status,
          },
          destinationIntegrations([MIXPANEL_DESTINATION])
        )
      } catch (error) {
        Analytics.track(
          PREVIEW_IMAGE_ON_CART_RE_FETCHED,
          {
            url: currentURL,
            handle: props.item?.variant.product?.handle,
            tags: props.item?.variant.product?.tags,
            stauts: -1,
            error: error as string,
          },
          destinationIntegrations([MIXPANEL_DESTINATION])
        )
      }
    }
  }, 1000)
}

function trackingViewPersonalizedOptionsOnCart() {
  if (showPersonalization && props.item?.variant?.product) {
    Analytics.track(
      EVENT_TRACKING_CLICK_PERSONALIZED_OPTIONS_ON_CART,
      {
        handle: props.item.variant.product.handle,
        tags: props.item.variant.product.tags,
      },
      destinationIntegrations([MIXPANEL_DESTINATION])
    )
  }
}

function onClickEditPersonalization() {
  emit('link-clicked')
  if (props.item?.variant?.product) {
    // Analytics.track(
    //   EVENT_TRACKING_CLICK_EDIT_PERSONALIZATION,
    //   {
    //     handle: props.item.variant.product.handle,
    //     tags: props.item.variant.product.tags,
    //   },
    //   destinationIntegrations([MIXPANEL_DESTINATION])
    // )
  }
}

function buildProductHandle(productPath: string) {
  // if product mademine we need ssr to redirect to customize domain
  const isMademineProduct = productPath.includes(MADEMINE_PRODUCT_HANDLE_PREFIX)
  if (isMademineProduct) {
    return `${routeStore.currentOrigin}${productPath}`
  }

  return productPath
}
</script>

<style lang="scss">
.cart {
  $S: &;
  &__item {
    display: grid;
    grid-template-columns: 120px minmax(0, 1fr);
    grid-template-rows: max-content 1fr;
    gap: 0 1em;
    grid-template-areas:
      'a b'
      'c b';
    margin-bottom: 2em;

    &-image {
      grid-area: a;
    }

    &-image-preview {
      grid-area: c;
      align-self: start;
      font-size: 12px;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.3em;
      color: var(--color-primary);

      svg {
        width: 1em;
        height: 1em;

        path {
          stroke: var(--color-primary);
        }
      }
    }

    &-edit-persionalization {
      font-size: 12px;
      font-weight: normal;
      color: var(--color-primary);
    }

    &-same-design {
      font-size: 12px;
      font-weight: 500;
    }

    $cartItem: &;

    &-content {
      grid-area: b;
      display: flex;
      flex-direction: column;
      gap: 0.75em;

      > #{$cartItem}-image-preview {
        display: none;
      }
    }

    &-price {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }

    &-price-final {
      color: var(--color-secondary);
    }

    &-price-compare {
      text-decoration: line-through;
      color: var(--text-color-tertiary);
    }

    &-options {
      font-size: 12px;
      color: var(--text-color-tertiary);
    }

    &-attributes {
      padding: 1em 0;
      border: dashed 1px var(--border-color);
      border-left: none;
      border-right: none;

      summary {
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
      }

      .font-weight-normal {
        font-weight: normal;
      }
    }

    &-attribute-list {
      margin-top: 1em;
      font-size: 12px;
      display: grid;
      grid-template-columns: 40% minmax(0, 1fr);
      gap: 0.5em;
      word-break: break-all;
    }

    &-attribute-title {
      color: var(--text-color-tertiary);
    }

    &-quantity-container {
      display: flex;
      align-items: center;
      gap: 1em;
    }

    .item__quantity {
      height: 2.25rem;
    }

    &-delete-button {
      cursor: pointer;
      display: flex;
      justify-content: center;

      svg {
        height: 1.8rem;
      }
    }

    &-donate-price {
      border-top: dashed 1px var(--border-color);
      padding-top: 1em;
      span {
        color: var(--color-secondary);
      }
    }
  }

  @include media-desktop {
    &--full-page {
      #{$S} {
        &__item {
          grid-template-columns: 135px minmax(0, 1fr);
          gap: 1.5em;

          &-image-preview {
            display: none;
          }

          &-content {
            display: grid;
            grid-template-columns: 5fr 3fr;
            grid-template-areas:
              'a b'
              'c b'
              'd b'
              'e b'
              'f b';

            & #{$S}__item-image-preview {
              display: flex;
              justify-content: flex-start;
            }
          }

          &-title {
            grid-area: a;
          }

          &-price {
            grid-area: c;
          }

          &-options {
            grid-area: d;
          }

          &-image-preview {
            grid-area: e;
          }

          &-attributes {
            grid-area: f;
          }

          &-quantity-container {
            grid-area: b;
            justify-content: space-evenly;
          }
        }
      }
    }
  }
  .custom-color-picker {
    position: relative;
    top: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid #b3b3b3;
    display: inline-block;
    margin-right: 4px;
  }
  .cart__item-express-shipping {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
  }
  .cart__item-bundle-wrapper {
    font-size: 12px;
    p {
      margin: 4px 0;
    }
  }
  .cart__item-bundle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    img {
      max-width: 60px;
      width: 100%;
      aspect-ratio: 1;
      object-fit: cover;
      border: 1px solid var(--border-color);
    }
  }
}
</style>

<template>
  <div class="cart">
    <CartItems
      @link-clicked="$emit('link-clicked')"
      @cart-recovery="recoverCartByEmail"
      v-model:loading="loading"
    />
    <CartPriceContainer />
    <CartRecentlyViewedItems
      v-if="checkoutStore.checkout && !checkoutStore.totalCartItems"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, h, onMounted, ref, watch } from 'vue'
import CartPrice from './CartPrice.vue'
import useDeviceStore from '@/store/device'
import CartItems from './CartItems.vue'
import { useFetchData } from '@/composables/fetchData'
import useCheckoutStore, { fillUpCheckoutAttributes } from '@/store/checkout'
import {
  CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY,
  getCurrentTimeStamp,
  UTMS_ATTRIBUTE_KEY,
  EVENT_TRACKING_CART_LOADED,
  MEASURE_NAME_CART_LOADED,
  TIME_MARK_CART_START_LOADING,
  SSR_LOAD_TYPE,
  EVENT_TRACKING_CHECKOUT_IS_NOT_VALID,
  MIXPANEL_DESTINATION,
  destinationIntegrations,
  EVENT_SAVE_DISCOUNT_CODE_TO_CACHE,
  QUERY_STRING_NO_CACHE,
} from '@/utils'
import useDiscountStore from '@/store/discount'
import { CheckoutLineItemUpdateInput } from '@/provider/type'
import Analytics from '@/services/analytics'
import useRouteStore from '@/store/route'
import { Checkout, NullOrType } from '@/types'
import { saveDiscountCodeToCache } from '@/utils/discount'
import EventBus from '@/services/eventbus'
import useCollectionStore from '@/store/collection'
import { useRoute } from 'vue-router'

const CartRecentlyViewedItems = defineAsyncComponent(
  () => import('./CartRecentlyViewedItems.vue')
)

const routeStore = useRouteStore()
const collectionStore = useCollectionStore()
const route = useRoute()

const props = withDefaults(
  defineProps<{
    wrapper: HTMLElement | null
    isUseWrapper: boolean
    isFullPage: boolean
  }>(),
  {}
)

const emit = defineEmits<{
  (e: 'link-clicked'): void
}>()

performance.mark(TIME_MARK_CART_START_LOADING)

const newProps = computed(() => {
  return {
    wrapper: props.wrapper,
    isUseWrapper: props.isUseWrapper,
    isFullPage: props.isFullPage,
    itemLoading: loading.value,
    onLinkClicked() {
      emit('link-clicked')
    },
  }
})

const cartTTL = 10 * 60 * 1000 // 10 minutes
const lastTimeFetchCheckout = ref(getCurrentTimeStamp())
const checkoutStore = useCheckoutStore()
const discountStore = useDiscountStore()
const { fetchData, loading } = useFetchData({
  fetchDataFunction: fetchCheckout,
})

onMounted(async () => {
  await fetchData()
  applyDiscount()
  fetchUpselCollection()
  // Update cart attribute after a time out to prevent storefront API rate limit
  setTimeout(() => {
    Analytics.excuteOnReady(async () => {
      if (!checkoutStore.checkout) return
      const currentCheckoutAttributes = checkoutStore.checkout.customAttributes
      const newCheckoutAttributes = await fillUpCheckoutAttributes(
        currentCheckoutAttributes
      )
      // Only update checkout attributes when utms changed or key added
      if (currentCheckoutAttributes?.length === newCheckoutAttributes.length) {
        const currentUtms = currentCheckoutAttributes?.find(
          (attr) => attr.key === UTMS_ATTRIBUTE_KEY
        )
        const newUtms = newCheckoutAttributes?.find(
          (attr) => attr.key === UTMS_ATTRIBUTE_KEY
        )
        if (currentUtms?.value === newUtms?.value) return
      }

      checkoutStore.updateCheckoutCustomAttributes({
        checkoutId: checkoutStore.checkout?.id,
        input: {
          customAttributes: newCheckoutAttributes,
        },
      })
    })
  }, 200)
  // trackingCartLoaded()

  // recover cart when user visit site from klaviyo and has checkoutId
  watch(
    () => checkoutStore.shouldRecoveryCheckoutIdBaseOnEmailKlaviyo,
    async () => {
      if (checkoutStore.shouldRecoveryCheckoutIdBaseOnEmailKlaviyo) {
        checkoutStore.checkout = null
        await fetchData()
      }
    }
  )
  watch(
    () => checkoutStore.totalCartItems,
    async (value) => {
      const priceContainer = document.querySelector('.cart__price-container')
      if (!priceContainer) return
      if (value) {
        priceContainer.classList.remove('cart__price-container-no-sticky')
      } else {
        priceContainer.classList.add('cart__price-container-no-sticky')
      }
    },
    {
      immediate: true,
    }
  )
})

async function recoverCartByEmail() {
  checkoutStore.checkout = null
  await fetchData()
}

function trackingCartLoaded() {
  if (routeStore.loadType !== SSR_LOAD_TYPE) return
  const selfTimeCartLoaded = performance.measure(
    MEASURE_NAME_CART_LOADED,
    TIME_MARK_CART_START_LOADING
  ).duration
  Analytics.track(
    EVENT_TRACKING_CART_LOADED,
    {
      performance_time: performance.now(),
      self_time: selfTimeCartLoaded,
    },
    destinationIntegrations([MIXPANEL_DESTINATION])
  )
}

async function fetchCheckout() {
  lastTimeFetchCheckout.value = getCurrentTimeStamp()
  let checkout = await checkoutStore.getCurrentCheckout()
  // Create new checkout if not exist or already completed
  if (!isCheckoutValid(checkout)) {
    checkout = await checkoutStore.createCheckout({
      lineItems: [],
    })
  }
  checkoutStore.saveCheckoutAndLineItems(checkout)
}

async function fetchUpselCollection() {
  if (collectionStore.upsellCollection) return
  const upsellCollectionHanlde = 'same-design'
  const isForceLoadNewData = !!route.query[QUERY_STRING_NO_CACHE]
  const payload = {
    handle: upsellCollectionHanlde,
    isForceLoadNewData,
  }
  await collectionStore.getUpsellCollectionByHandle(payload)
}

function isCheckoutValid(checkout: NullOrType<Checkout>) {
  if (!checkout || checkout.completedAt) {
    Analytics.track(
      EVENT_TRACKING_CHECKOUT_IS_NOT_VALID,
      {
        checkout_id: checkout?.id,
        is_completed_at: !!checkout?.completedAt,
        is_variant_null: false,
      },
      destinationIntegrations([MIXPANEL_DESTINATION])
    )
    return false
  }
  if (checkout.lineItems) {
    for (let i = 0; i < checkout.lineItems.length; i++) {
      const lineItem = checkout.lineItems[i]
      // sometimes the variant in lineitem will be null when it is drafted or deleted from Shopify admin
      if (!lineItem.variant) {
        Analytics.track(
          EVENT_TRACKING_CHECKOUT_IS_NOT_VALID,
          {
            checkout_id: checkout.id,
            is_completed_at: false,
            is_variant_null: true,
          },
          destinationIntegrations([MIXPANEL_DESTINATION])
        )
        return false
      }
    }
  }
  return true
}

function checkRefreshCart() {
  const currentTimeStamp = getCurrentTimeStamp()
  if (currentTimeStamp - lastTimeFetchCheckout.value > cartTTL) {
    fetchData()
  }
}

async function applyDiscount() {
  if (!checkoutStore.checkout) return

  // check for update discount campaign
  const lineItemsToUpdate: CheckoutLineItemUpdateInput[] = []
  checkoutStore.localCartLineItems.forEach((lineItem) => {
    const customAttributes = [...lineItem.customAttributes].map((elm) => {
      return {
        key: elm.key,
        value: elm.value,
      }
    })
    const campaignAttributeIndex = customAttributes.findIndex((attribute) => {
      return attribute.key === CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY
    })
    let campaignAttribute = customAttributes[campaignAttributeIndex]

    // remove the campaign attribute in case can not find any campaign discount code
    if (!discountStore.campaignDiscountCode) {
      if (campaignAttributeIndex == -1) return
      customAttributes.splice(campaignAttributeIndex, 1)
    }

    // Update the existing or add campaign attribute in case find campaign discount code on the client
    if (campaignAttribute) {
      if (campaignAttribute.value === discountStore.campaignDiscountCode) {
        return
      }
      campaignAttribute.value = discountStore.campaignDiscountCode!
    } else {
      campaignAttribute = {
        key: CAMPAIGN_DISCOUNT_LINE_ITEM_ATTRIBUTE_KEY,
        value: discountStore.campaignDiscountCode!,
      }
      customAttributes.push(campaignAttribute)
    }

    // push the lineitem to to update items
    lineItemsToUpdate.push({
      id: lineItem.id,
      customAttributes,
    })
  })

  if (lineItemsToUpdate.length > 0) {
    const timeStamp = getCurrentTimeStamp()
    checkoutStore.updateCartLineItems({
      lineItems: lineItemsToUpdate,
      timeStamp,
    })
  }

  // check for update discount code
  if (discountStore.discountCode) {
    if (!checkoutStore.discountCodeCurrentApply) {
      const respone = await checkoutStore.checkoutDiscountCodeApply(
        discountStore.discountCode
      )
      if (respone?.checkoutErrors.length && respone.checkoutErrors[0].message) {
        // remove recently discount code
        const isRemove = true
        const domain = `.${routeStore.currentDomain}`
        await saveDiscountCodeToCache(
          discountStore.discountCode,
          domain,
          isRemove
        )
        return
      }
    }
    const domain = `.${routeStore.currentDomain}`
    await saveDiscountCodeToCache(discountStore.discountCode, domain)
    EventBus.trigger(EVENT_SAVE_DISCOUNT_CODE_TO_CACHE)
  }
}

defineExpose({
  checkRefreshCart,
})

const deviceStore = useDeviceStore()
const CartPriceContainer = () => {
  if (!deviceStore.isMobile && props.isFullPage) {
    return h(
      'div',
      { class: 'cart__price-container' },
      h(CartPrice, newProps.value)
    )
  }
  return h(CartPrice, newProps.value)
}
</script>

<style lang="scss">
.cart {
  $S: &;

  @include media-desktop {
    &--full-page {
      display: grid;
      grid-template-columns: 5fr minmax(300px, 2fr);
      grid-template-areas:
        'a b'
        'c c';
      gap: 2em;

      > .divider {
        display: none;
      }
    }
  }
}
.cart__price-container-no-sticky {
  position: unset !important;
}
</style>
